import React, { useCallback, useState, useContext, useMemo, useEffect } from 'react'
import { Context } from './Context'
import { motion as m } from 'framer-motion'
import styled from 'styled-components'
import Fuzzy from 'react-fuzzy'
import { useHistory } from 'react-router-dom'
import { pathAsRoute } from './services/RouteParser'

const QuickNavigate = () => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const { leaves } = useContext(Context)
  const mappedLeaves = useMemo(() => leaves.map((leaf, i) => ({ title: leaf, id: i })), [leaves])

  const onSelect = useCallback(
    (item) => {
      setOpen(false)
      history.push(pathAsRoute(item.title))
    },
    [history]
  )

  const onKeyDown = useCallback(
    ({ key, ctrlKey }) => {
      if (key == 'p' && ctrlKey) {
        setOpen(!open)
      }
    },
    [open]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [onKeyDown])

  if (!open) {
    return null
  }

  return (
    <Wrapper>
      <Fuzzy autoFocus list={mappedLeaves} keys={['title']} onSelect={onSelect} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
`

const Container = styled.div`
  position: relative;
  width: 50%;
  height: 50%;
`

export { QuickNavigate }
