import React, { useContext } from 'react'
import { Context } from './Context'
import { Switch, Route } from 'react-router-dom'
import { pathAsRoute } from './services/RouteParser'
import { Leaf } from './Leaf'

export const Routes = () => {
  const { leaves } = useContext(Context)

  return (
    <Switch>
      {leaves.map((leaf) => {
        const path = pathAsRoute(leaf)
        return (
          <Route
            exact
            key={path}
            path={path}
            render={(...props) => <Leaf {...props} path={leaf} isTopLevel={true} />}
          />
        )
      })}
    </Switch>
  )
}
