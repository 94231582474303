import React, { useEffect, useState, useContext, createContext } from 'react'
import styled from 'styled-components'
import { Renderer } from './Renderer'
import { LeafLink } from './LeafLink'
import { Link } from 'react-router-dom'
import { Context } from './Context'

function Leaf({ path, isTopLevel }) {
  const { data } = useContext(Context)

  return (
    <Container>
      {isTopLevel && (
        <TopLevelLink>
          <Link to="/">/</Link>
        </TopLevelLink>
      )}
      <small>
        <LeafLink path={path}>{path}</LeafLink>
      </small>
      <Renderer>{(data[path] && data[path].contents) || ''}</Renderer>
    </Container>
  )
}

const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding-left: 1em;
`

const TopLevelLink = styled.small`
  margin-right: 1em;
`

export { Leaf }
