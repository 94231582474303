import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { db } from './services/firebase'
import styled from 'styled-components'
import { Task } from './Task'

const TaskList = ({ tags: inputTags }) => {
  const tags = useMemo(() => inputTags.split('/'), [inputTags])
  const [tasks, setTasks] = useState([])
  const [comp, setComp] = useState()

  const sortBy = useCallback(
    (by, neg) => (a, b) => {
      if (a[by] && b[by]) {
        return parseInt(a.key) - parseInt(b.key)
      } else if (a[by]) {
        return -1 * (neg || 1)
      } else {
        return 1 * (neg || 1)
      }
    },
    []
  )

  useEffect(() => {
    db.ref('/tasks').on('value', (ss) => {
      const temp = []
      ss.forEach((snapshot) => {
        const v = snapshot.val()
        let hasTag = false
        tags.forEach((t) => {
          if (v.tags?.includes(t)) {
            hasTag = true
          }
        })

        if (hasTag) {
          temp.push({ id: snapshot.key, ...v })
        }
      })

      setTasks(temp.sort(sortBy('prioritized')))
    })
  }, [tags, sortBy])

  const sortedTasks = useMemo(() => {
    if (comp === undefined) {
      return tasks
    }
    if (typeof comp == 'string') {
      return tasks.sort(sortBy(comp))
    } else {
      const [key, neg] = comp
      return tasks.sort(sortBy(key, neg))
    }
  }, [tasks, comp, sortBy])

  return (
    <Container>
      <SortByRow>
        <Button onClick={() => setComp('prioritized')}>Prioritized</Button>
        <Button onClick={() => setComp(['done', -1])}>Done</Button>
        <Button onClick={() => setComp('blocked')}>Blocked</Button>
      </SortByRow>
      {sortedTasks.map((t) => (
        <Task id={t.id} key={t.id} open={t.prioritized} />
      ))}
    </Container>
  )
}

const SortByRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`

const Container = styled.div`
  margin-top: 64px;
`

const Button = styled.button`
  border: 0;
  font-family: inherit;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 4px;

  &:focus {
    outline: 0;
    border-color: black;
  }
`

export { TaskList }
