import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { motion as m } from 'framer-motion'
import { db } from './services/firebase'
import { serialize, deserialize } from 'react-serialize'
import { useLocation } from 'react-router-dom'
import { Renderer } from './Renderer'

const useDb = (path, initial) => {
  const [value, setValue] = useState(initial)
  const [pulled, setPulled] = useState(false)

  const set = useCallback(
    (v) => {
      if (!path.includes('undefined')) {
        db.ref(path).set(v)
      }
    },
    [path]
  )

  useEffect(() => {
    const listener = (ss) => {
      setPulled(true)
      setValue(ss.val())
    }

    db.ref(path).on('value', listener)

    return () => db.ref(path).off('value', listener)
  }, [path])

  return [value, set]
}

const Task = ({ children, due, id, open: initialOpen, ...rest }) => {
  const [done, setDone] = useDb(`/tasks/${id}/done`, false)
  const [prioritized, setPrioritized] = useDb(`/tasks/${id}/prioritized`, false)
  const [blocked, setBlocked] = useDb(`/tasks/${id}/blocked`, false)
  const [inProgress, setInProgress] = useDb(`/tasks/${id}/inprogress`, false)
  const [contents, setContents] = useDb(`/tasks/${id}/contents`, '')

  const location = useLocation()
  const [open, setOpen] = useState(initialOpen || false)

  useEffect(() => {
    if (done) {
      setOpen(false)
    }
  }, [done])

  const checkboxVariants = {
    on: {
      background: 'rgba(0, 0, 0, 1)'
    },
    off: {
      background: 'rgba(0, 0, 0, 0)'
    }
  }

  const title = useMemo(() => (contents.length ? contents.split('\n')[1] : ''), [contents])

  return (
    <TaskContainer open={open} {...rest}>
      <TaskLine>
        <CheckBox onClick={() => setDone(!done)} variants={checkboxVariants} animate={done ? 'on' : 'off'} />
        <CheckBoxLabel>Done</CheckBoxLabel>
        {due && <span>due: {due}</span>}
        <Spacer>{!open && <Renderer>{title}</Renderer>}</Spacer>
        <CheckBox
          onClick={() => setInProgress(!inProgress)}
          variants={checkboxVariants}
          animate={inProgress ? 'on' : 'off'}
        />
        <CheckBoxLabel>In Progress</CheckBoxLabel>
        <CheckBox
          onClick={() => setPrioritized(!prioritized)}
          variants={checkboxVariants}
          animate={prioritized ? 'on' : 'off'}
        />
        <CheckBoxLabel>Prioritize</CheckBoxLabel>
        <CheckBox onClick={() => setBlocked(!blocked)} variants={checkboxVariants} animate={blocked ? 'on' : 'off'} />
        <CheckBoxLabel>Blocked</CheckBoxLabel>
        <Open onClick={() => setOpen(!open)}>{open ? '<' : '>'}</Open>
      </TaskLine>
      {open && <Renderer>{contents}</Renderer>}
      {open && <IdText>{id}</IdText>}
    </TaskContainer>
  )
}

const Open = styled.button`
  border: 1px solid black;
  font-family: inherit;
  font-weight: inherit;
`

const Spacer = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TaskContainer = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.5);
  // border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding: 12px;
  border-radius: 4px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 16px;

  ${({ open }) =>
    !open &&
    `
      padding-bottom: 4px;
    p {
      margin: 0;
      margin-right: 16px;
    }
  `}
`

const TaskLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const CheckBoxLabel = styled.div`
  margin: 0;
  margin-right: 16px;
  font-size: 0.8em;
`

const CheckBox = styled(m.div)`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid black;
`

const IdText = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.5em;
  margin: 4px;
  opacity: 0.5;
`

export { Task }
