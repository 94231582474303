import React from 'react'
import Markdown from 'markdown-to-jsx'
import { Leaf } from './Leaf'
import { Task } from './Task'
import { TaskList } from './TaskList'

const Renderer = ({ children }) => {
  return (
    <Markdown
      options={{
        overrides: {
          Leaf: {
            component: Leaf
          },
          Task: {
            component: Task
          },
          TaskList: {
            component: TaskList
          }
        }
      }}
      children={children}
    />
  )
}

export { Renderer }
