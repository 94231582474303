import firebase from 'firebase'

const config = {
  apiKey: 'AIzaSyDYSTHGP3M57WhpwsYIbfVTGHiPybpbHjM',
  authDomain: 'garden-478f1.firebaseapp.com',
  databaseURL: 'https://garden-478f1.firebaseio.com',
  projectId: 'garden-478f1',
  storageBucket: 'garden-478f1.appspot.com',
  messagingSenderId: '142452926385',
  appId: '1:142452926385:web:c378b55e6d4a8df3c0ff90',
  measurementId: 'G-KR3SZ157TX'
}

firebase.initializeApp(config)
export const db = firebase.database()
