import React, { createContext, useState, useEffect, useReducer } from 'react'
import { db } from './services/firebase'

export const Context = createContext()

export const Provider = ({ children }) => {
  const [leaves, setLeaves] = useState([])
  const [data, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'add_page':
        return { ...state, [action.key]: action.data }
      default:
        return
    }
  }, {})

  useEffect(() => {
    db.ref('/leaves').on('value', (ss) => {
      setLeaves(ss.val())
    })
  }, [])

  useEffect(() => {
    const listener = () => {
      leaves.forEach((leaf) => {
        db.ref(`/pages/${leaf}`).once('value', (ss) => {
          dispatch({ type: 'add_page', key: ss.val().path, data: ss.val() })
        })
      })
    }
    db.ref('/pages').on('value', listener)

    return () => db.ref('/pages').off('value', listener)
  }, [leaves])

  const state = {
    leaves,
    data
  }

  return <Context.Provider value={state}>{children}</Context.Provider>
}
