import React from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from './Context'
import { Routes } from './Routes'
import { Task } from './Task'
import { QuickNavigate } from './QuickNavigate'

function App() {
  return (
    <Page>
      <Router>
        <Container>
          <Provider>
            <Routes />
            <QuickNavigate />
          </Provider>
        </Container>
      </Router>
    </Page>
  )
}

const Page = styled.div`
  padding: 16px;
`

const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
`

export default App
